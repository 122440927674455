.object-balloon button {
  margin: 2px;
  border-width: 2px;
  border-radius: 10px;
  border-color: rgba(0,0,0,0.4);
  background-color: white;
}

.object-balloon button:hover {
  cursor: pointer;
}

.object-balloon button:active {
  cursor: pointer;
  background-color: rgba(0,0,0,0.05);
}
